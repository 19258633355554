<div class="home-image" style="background-image: url(../assets/plants/crocus-tommasinianus-barrs-purple.jpg)">
</div>
<div class="wrapper">
  <h1>Support Great Plant Picks</h1>
  <div class="content">
    <div class="text-wrap">
      <p>Visa, MasterCard, Discover and debit cards accepted. All transactions processed securely via PayPal, or donate via mail or phone (see below).</p>
      <p><strong>How is my donation going to be used?</strong></p>
      <ul>
        <li>Your contribution helps fund the research and selection process to pick the best performing plants for the
          maritime Pacific Northwest.
        </li>
        <li>Your contribution helps to review, evaluate, and assess the tens of thousands of ornamental plants available
          and distill them to the very best for gardeners in our region.
        </li>
      </ul>
      <p><strong>What difference will my contribution make?</strong></p>
      <ul>
        <li>Your contribution keeps GPP non-biased.</li>
        <li>Your contribution supports plant trials and evaluations.</li>
        <li>Your contribution helps to maintain GPP as one of the premier plant education programs in the country.</li>
      </ul>
    </div>
    <h2>I would like to help Great Plant Picks with a tax-deductible contribution</h2>
    <div class="text-wrap">
      <p>Donate $30, $60, $90 or the amount of your choice using a credit or debit card or your PayPal account, with the
        option of making a monthly contribution.</p>
      <form #form class="donation" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_s-xclick"/>
        <input type="hidden" name="hosted_button_id" value="5V8FDQ4XP5D5A"/>
        <input type="image" (click)="form.submit()" src="assets/donate.png" border="0"
               name="submit" title="Donate to Help Support Great Plant Picks"
               alt="Donate to Help Support Great Plant Picks"/>
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
      </form>

      <h2>Donate by phone or mail</h2>
      <p>If you have any questions or would prefer to donate by phone, please call Great Plant Picks Manager Rick
        Peterson at (206) 362-8612. Visa, MasterCard, Discover, and check accepted for your phone donation. To donate by
        mail, send a check payable to the Miller Garden to the address below.</p>
      <p><strong>Great Plant Picks</strong><br>
        Attn: Rick Peterson, GPP Manager<br>
        PO Box 77377<br>
        Seattle, WA 98177<br>
        (206) 362-8612</p>
    </div><!-- .text-wrap -->
  </div><!-- end content -->
</div><!-- wrapper -->
